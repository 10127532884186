<template>
  <div
    id="app-v"
    :class="{
      'no-desktop': !store.getters.isDesktop,
      'app-terminal': store.getters.layoutTerminal,
      'no-integration scrollable': !store.getters.isIntegration,
      'ticket-history-route': isSevenTicketHistory,
      'no-cursor': store.getters.removeCursor
    }"
  >
    <TemplateDefault v-if="appIsReady" />
    <CircleLoader v-if="loader" />
    <TicketsHistory v-if="isLoggedIn" />
    <FullScreen v-if="isAndroid && isMobileDevice && !isFirefox" />
  </div>
</template>

<script setup>
import isMobile from 'ismobilejs';
import applyPalette from '@nsftx/games-sdk-js/src/utility/applyPalette';
import CircleLoader from '@nsftx/games-sdk-js/src/components/CircleLoader';
import FullScreen from '@nsftx/games-sdk-js/src/components/FullScreen';
import {computed, onBeforeMount, onBeforeUnmount, ref} from 'vue';
import { useStore } from 'vuex';
import TemplateDefault from '@/layouts/TemplateDefault';
import { init, initResize, removeResize } from '@/utility';
import EventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import TicketsHistory from '@/components/TicketHistory/TicketsHistory';
import has from 'lodash/has';
import {forEachRight, forEach} from 'lodash';
import types from '@/store/mutationTypes';
import { useRoute } from 'vue-router'

const store = useStore();
const route = useRoute();
const appIsReady = ref(null);
const loader = computed(() => store.getters.isLoaderActive);
const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);
const isMobileDevice = computed(() => store.getters['isMobile']);
const isAndroid = computed(() => store.getters['isAndroid']);
const isFirefox = computed(() => isMobile.other?.firefox);

const setTheme = () => {
const {
  config: { baseColor, theme },
} = store.getters.ui;
const rootElement = document.documentElement;
const bodyElement = document.body;
applyPalette.set(store.getters.ui[baseColor], rootElement);
applyPalette.set(store.getters.ui[theme], bodyElement);
};
const checkOfferAndBets = () => {
  store.dispatch('setInternetConnection', true);
  const tempTime = new Date().getTime();
  const offer = store.getters.offer;
  const bets = store.getters['gamesBetslip/ticket'];
  const newOffer = [];
  let removeActive = false;

  forEachRight(offer, (match) => {
    const matchTime = new Date(match.timestamp).getTime() - 6000;
    if (tempTime > matchTime) {
      removeActive = true;
      forEach(bets, (bet) => {
        if (bet.roundNumber === match.eventIdToday) {
          // eslint-disable-next-line no-param-reassign
          store.dispatch('gamesBetslip/updateBet', {
            ...bet,
            locked: true,
            permanentlyLock: true,
          });
        }
      })
    } else {
      newOffer.unshift(match);
    }
  });
  store.commit(types.SET_OFFER, newOffer);
  setTimeout(() => {
    if (removeActive) {
      document.getElementsByClassName('schedule-wrapper')[0].getElementsByClassName('round-wrapper')[0].click();
    }
  }, 100);
};
const offline = () => {
  store.dispatch('setInternetConnection', false);
};
const online = () => {
  window.addEventListener('online', checkOfferAndBets);
};
const gameVisibilityChange = () => {
  const tempDate = new Date().getTime() + 6000;
  const nextEventDate = store.getters.offer[0]?.timestamp;
  const nextEventDateTimestamoUtc = nextEventDate ? new Date(nextEventDate).getTime() : null;
  if (!nextEventDateTimestamoUtc) return;
  if (tempDate >= nextEventDateTimestamoUtc || store.getters.config.rules.maxNumEvents.value !== store.getters.offer.length) {
    const hardcodedDataOfStopBettingMessage = {
      data: {
        duration: 5,
      },
      eventName: "stopBetting",
    };
    store.dispatch('getOffer', hardcodedDataOfStopBettingMessage);
  }
};

const isSevenTicketHistory = computed(() => route.name === 'ticketsHistory');

onBeforeMount(async () => {
  console.log(route);
  window.addEventListener('offline', offline);
  window.addEventListener('online', checkOfferAndBets);
  window.addEventListener('visibilitychange', gameVisibilityChange);

  initResize(store);
  appIsReady.value = await init(store);
  if (store.getters.config) {
    if (store.getters.layoutTerminal && store.getters.screenSize < 1280) {
      store.dispatch('gamesBetslip/setActiveTicketType', store.getters['gamesBetslip/activeTicketTypes'][0]);
      store.dispatch('setIsBetslipOpen', false);
    }
    if (!store.getters.layoutTerminal) {
      store.dispatch('setIsBetslipOpen', store.getters.screenSize < 660 || store.getters.screenSize >= 960);
    }
    setTheme();
  }
  EventBus.$on('openTicketHistory', () => {
    store.dispatch('toggleTicketHistory', true);
  });
  EventBus.$on('ReturnToLobby', () => {
    window.busService.sendMessage({
        action: 'UI.Show',
        data: {
          name: ['Lobby'],
        },
      }, true);
    if (store.getters.isAndroid && has(window, 'WebAppListener')) {
      window.WebAppListener.sendMessage('Navigation.Back');
    }
  });
});
onBeforeUnmount(() => {
  removeResize();
  EventBus.$off('openTicketHistory');
  EventBus.$off('ReturnToLobby');
  window.removeEventListener('online', online);
  window.removeEventListener('offline', offline);
  window.removeEventListener('visibilitychange', gameVisibilityChange);
  EventBus.$off('updateBalance');
});
</script>

<style lang="scss">
@use './styles/globals';
html,
body {
  background: var(--background-1);
  color: var(--text-primary-1);
  font-size: 14px;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app-v {
  &.no-desktop,
  &.no-integration,
  &.app-terminal {
    height: 100%;
  }
  &.ticket-history-route {
    .tickets-history {
      min-height: auto;
    }
  }
}
.info-wrapper {
  .tabs {
    .tab-button {
      max-width: none !important;
    }
  }
}
.tab-button {
  position: relative;
  .counter {
    position: absolute;
    background-color: var(--secondary);
    color: var(--text-secondary-1);
    font-size: var(--font-size-small, 12px);
    width: 16px;
    height: 16px;
    span {
      float: left;
      width: 100%;
      height: 100%;
      line-height: 18px;
      color: var(--text-secondary-1);
      font-size: 10px;
      font-weight: bold;
    }
  }
  span + .counter {
    top: 11px;
    right: 8px;
    border-radius: 2px;
  }
  .icon + .counter {
    top: 4px;
    right: 13px;
    border-radius: 50%;
  }
  > button {
    text-transform: capitalize;
  }
  @media screen and (min-width: 660px){
    max-width: 190px !important;
  }
}
.status-lost,
.status-closed {
  color: var(--system-negative);
}

.status-won,
.status-payedout,
.status-paidout {
  color: var(--system-positive);
}

.status-open,
.status-in_play,
.status-pending {
  color: var(--system-neutral);
}
.betslipBFooter .betslipOpenButton.hasBets .button {
color: var(--text-secondary-1) !important;
}
.secondary-opacity {
  color: var(--text-primary-2);
}
.app-terminal {
  font-size: 16px;
  @media screen and (min-width: 1920px) {
    font-size: 20px !important;
  }
  .schedule-wrapper {
    height: 72px !important;
    margin: 4px 0 !important;
    @media screen and (min-width: 1920px) {
      height: 88px !important;
    }
    button {
      height: 54px;
      @media screen and (min-width: 1920px) {
        height: 72px !important;
      }
    }
    > button {
      max-width: 54px !important;
      i {
        font-size: 20px;
      }
      @media screen and (min-width: 1920px) {
        i {
          font-size: 24px;
        }
      }
    }
    .content-wrapper {
      //height: 100%;
      padding: 0 4px;
      width: auto;
      button {
        font-weight: 500;
        line-height: 16px;
        width: 174px !important;
        &.round-wrapper {
          margin: 0 4px !important;
        }
      }
      .round-time {
        font-size: 16px;
      }
      .round-number {
        font-size: 16px;
      }
      @media screen and (min-width: 1920px) {
        button {
          font-weight: 500;
          line-height: 20px;
          width: 244px !important;
          .round-time {
            font-size: 24px !important;
          }
          .round-number {
            font-size: 20px !important;
          }
        }
      }
    }
  }
  .circle.normal {
    height: 38px !important;
    width: 38px !important;
    .jersey-wrapper {
      height: 34px !important;
      width: 34px !important;
    }
    svg {
      height: 25px !important;
    }
    @media screen and (min-width: 1920px) {
      height: 56px !important;
      width: 56px !important;
      .jersey-wrapper {
        height: 52px !important;
        width: 52px !important;
      }
      svg {
        height: 25px !important;
      }
    }
  }
  .betting-match-title-wrapper {
    height: 54px;
    @media screen and (min-width: 1920px) {
      height: 72px !important;
      .betting-match-title {
        font-size: 24px;
      }
    }
  }
  .betting-info-wrapper {
    .betting-wrapper {
      width: 100% !important;
      .round-wrapper {
        height: 54px !important;
        @media screen and (min-width: 1920px) {
          line-height: 24px !important;
          height: 72px !important;
          >div:first-child {
            font-size: 24px !important;
          }
          >div:nth-child(2) {
            font-size: 20px !important;
          }
        }
        > div {
          font-size: 16px;
        }
      }
      @media screen and (min-width: 1920px) {
        .market-title {
          font-size: 20px !important;
        }
      }
    }
  }
  &.no-cursor {
    cursor: none !important;
    button,
    input,
    i,
    .schedule-wrapper,
    .content-wrapper {
      cursor: none !important;
    }
  }
}
.betslipBMobile .betslipA {
  bottom: 96px !important;
}
.backoffice-ticket-check-wrapper {
  .header-wrapper .ticket-id .icon-copy {
    display: none;
  }
}
.scrollable {
  > * {
    overflow: auto;
    scrollbar-width: none;
    scrollbar-color: var(--text-primary-3) var(--card);
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: var(--card);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--button);
    border-radius: 3px;
  }
}

@media (max-width: 660px) {
  .outcome-wrap {
    .bet-outcome {
      color: var(--text-primary-1) !important;
    }
  }
}
@media (min-width: 306px) and (max-width: 660px) {
  .schedule-wrapper .content-wrapper {
    overflow: auto;
    scrollbar-width: none;
    scrollbar-color: var(--text-primary-3) var(--card);
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 3px;
    }
  }
}
</style>
